{
  /*
  // TODO
  - Unlayer Loaded and Export as HTML and JSON is working - Working
  - On Saving the template should be saving to the database
  - Devices to be checked on Desktop / Tablet / Mobile 
  - useNavigate hook should be used for routing purpose

  // NOTE
  - Toggle Theme will reset the editor by default so either fix it or remove it same goes for Panel Dock
  - Template Controller - server , Save Editor
  - Save editor -  Asset Library
  - Email Editor - Full Screen
  - Test Email - Enable it  - Not Able to Find it
  - Reset Canvas functionality / Clear Template with clear email
  - Back Button - functionality
  - Edit - Email Properties functionality
  - Full screen Functionality with navbar (Prior Made With GrapeJS Command)
  - If There are any changes in Edit then only enable save editor function
  - Form is Avaliable is page builder - web
  - Make Show hide line if possible (Show Grid)
  - Upload section should contain search functionality otherwise it is not 


  // REVIEW (Considered as done)
   - Create / Delete Emails
   - Edit Email Template - (SomeTimes changes saved but now reflecting)
   - Test Sending Working Properly 
   - Edit Email Property

  //  ANCHOR - Premium Items
  - Create a Custom Tab
  - Audit Content/Stock Images/Image Editor

*/
}
// console.log(":Editor.js:");

import React, { useRef, useState, useEffect } from "react";
import EmailEditor from "react-email-editor";
import { connect } from "react-redux";
import useSaveAs from "../../../hooks/useSaveAs.ts";
import EditorNewNav from "./EditorNewNav.js";

import { saveEmail, editEmailDesign } from "../../Features/Email/EmailThunk";
import { reset } from "../../Features/Template/TemplateSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { editorTemplateById } from "../../Features/Editor/EditorThunk";
import axios from "../../Utils/Interceptor/AxiosInterceptor.js";
import {
  saveTemplate,
  editTemplateDesign,
} from "../../Features/Template/TemplateThunk.js";
import { emailById } from "../../Features/Asset/AssetThunk";
import Loading from "../../Loading/Loading.js";
import editorOptions, { generateMergeTags } from "./EditorConfig.js";
import { getImages } from "../../Features/Image/ImageThunk.js";
const Editor = (props) => {
  let {
    component,
    editTemplateDesign,
    alertRef,
    editorTemplateById,
    editParam,
    editEmailDesign,
    emailById,
    dataSlice,
  } = props;
  // console.log(props)
  const emailEditorRef = useRef(null);
  const [unlayerprojectIds, setUnlayerprojectIds] = useState([]);
  const { handleSave } = useSaveAs(emailEditorRef);
  const navigate = useNavigate();
  const [state, setState] = useState({
    preview: false,
    loading: false,
    redirect: {
      is: false,
      to: "",
    },
    mergeTagPopupOpen: false,
    autoSaveLoading: false,
  });


  const [editorLoaded, setEditorLoaded] = useState(false);

  /* SECTION Premium Function for Image Upload section 
  const images = imageSlice.imagesCollection.images;
  console.log(images);
  // NOTE For Premium use OnReady function
  // const onReady = async (unlayer) => {
  //   // Define userUploadsProvider function
  //   const userUploadsProvider = async function (params, done) {
  //     const page = params.page || 1;
  //     const perPage = params.perPage || 2;
  //     const total = 100;
  //     const hasMore = total > page * perPage;

  //     try {
  //       // Fetch images from an external source (e.g., Picsum)
  //       // const res = await fetch("https://picsum.photos/v2/list");
  //       const res = await fetch(
  //         `http://localhost:4000/api/v1/images?type=recent&limit=2&page=1&value=null`
  //       );
  //       const imagesData = await res.json();
  //       console.log(imagesData.data.images);
  //       const modifiedImages = imagesData.data.images.map((image) => ({
  //         id: image.image_id,
  //         location: image.image_path,
  //       }));
  //       console.log(modifiedImages);
  //       // Pass fetched images to the Unlayer editor
  //       done(modifiedImages, { hasMore, page, perPage, total });
  //     } catch (error) {
  //       console.error("Error fetching images:", error);
  //       // Handle error or provide an empty array to prevent issues
  //       done([], { hasMore, page, perPage, total });
  //     }
  //   };

  //   if (editParam) {
  //     // Load design based on the component type
  //     const designToLoad =
  //       component === "template"
  //         ? editorSlice.templateById.json
  //         : component === "email"
  //         ? assetSlice.emailById.json
  //         : null;

  //     if (designToLoad) {
  //       unlayer.loadDesign(designToLoad);
  //     }

  //     // Register userUploads provider conditionally
  //     unlayer.registerProvider("userUploads", userUploadsProvider);
  //   }
  // };
*/

  //NOTE  Get the Projects Keys directly here.

  useEffect(() => {
    // console.log("Editor.js: useEffect:");

    let isMounted = true;
    const fetchKeys = async () => {
      try {
        const response = await axios.get("/getKeys");
        // console.log(response)
        if (isMounted) {
          // console.log(" fetchKeys: unlayerprojectIds:", response.data.id);
          setUnlayerprojectIds(response.data.id);
        }
        // console.log("Response back",response.data)
        // console.log("Response back",response.data.message)
      } catch (error) {
        console.error("Error fetching project keys:", error);
      }
    };

    fetchKeys();
    return () => {
      isMounted = false;
    };
  }, []);

  const onLoad = () => {
    // console.log(" EmailEditor: onLoad: unlayerprojectIds:", unlayerprojectIds);
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  

  const onReady = async (unlayer) => {
    let { editParam, component, editorSlice, assetSlice } = props;

    // REVIEW Editor Post Images to s3 Bucket
    unlayer.registerCallback("image", function (file, done) {
      var data = new FormData();
      data.append("image", file.attachments[0]);

      //FIXME updated the url as per the enviorment.

      fetch("https://dev.apicharlie.dealercontentgroup.com/api/v1/images/upload/editor-asset", {
        method: "POST",
        body: data,
      })
        .then((response) => {
          // Ensure the response is valid
          if (!response.ok) {
            throw new Error("Upload failed");
          }
          return response.json();
        })
        .then((data) => {
          // Pass the URL back to Unlayer to mark this upload as completed
          done({ progress: 100, url: data.imageUrl });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          done({ error: error.message }); // Mark upload as failed
        });
    });

    if (editParam && component === "template") {
      let { templateById } = editorSlice;
      unlayer.loadDesign(templateById.json);

      // console.log(
      //   " EmailEditor: 'template' unlayerprojectIds:",
      //   unlayerprojectIds
      // );
    }
    if (editParam && component === "email") {
      let { emailById } = assetSlice;
      // console.log(emailById);
      unlayer.loadDesign(emailById.json);

      // console.log("email unlayerprojectIds:", unlayerprojectIds);
    }
    setEditorLoaded(true);

  };

  // SECTION Reset Email Canvas
  const handleReset = () => {
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      unlayer.loadBlank({
        // Changeable Color - customizable
        backgroundColor: "#e7e7e7",
      });
    } else {
      console.error("Unlayer editor is not available.");
    }
  };

  // SECTION - Save Editor Function
  const saveEditor = async () => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer.exportHtml(async (data) => {
      const { design, html } = data;
      try {
        // destructing from props
        let { component, alertRef } = props;
        let response = {};
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
        if (component === "template") {
          let { template, saveTemplate, reset, alertRef } = props;
          let categories = template.categories.categoryBundle.toString();
          let templateData = {
            name: template.templateName.textName,
            location: template.location.path,
            categories: categories,
            description: template.description.textDes,
            // FIXME - Passing string from frontend but converting it into json file in backend
            templatejson: design,
            templateHtml: html,
          };
          response = await saveTemplate(templateData);
          let { payload } = response;
          console.log(response);
          alertRef.current.showToaster(payload);
          await reset();
          navigate("/assetlibrary/templates");
        } else {
          // SECTION - If editor is sending data for email
          let { email, reset, saveEmail } = props;
          let emailData = {
            name: email.emailName.textName,
            location: email.location.path,
            subject: email.subject.textSubject,
            description: email.description.textDes,
            emailJson: design,
            emailHtml: html,
          };
          console.log(emailData);
          response = await saveEmail(emailData);
          let { payload } = response;
          alertRef.current.showToaster(payload);
          await reset();
          navigate("/assetlibrary/emails");
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  // SECTION - Edit Editor function
  const editEditor = async () => {
    try {
      setState((prevState) => ({ ...prevState, autoSaveLoading: true }));
      const { design, html } = await new Promise((resolve, reject) => {
        emailEditorRef.current.editor.exportHtml((data) => {
          resolve(data);
        });
      });
      if (component === "template") {
        const response = await handleTemplateEdit(design, html);
        // console.log(response);
        setState((prevState) => ({ ...prevState, autoSaveLoading: false }));
        alertRef.current.showToaster(response.payload);
      }

      if (component === "email") {
        const response = await handleEmailEdit(design, html);
        // console.log(response);
        setState((prevState) => ({ ...prevState, autoSaveLoading: false }));
        alertRef.current.showToaster(response.payload);
      }
    } catch (error) {
      console.warn("An Error Occured", error);
    }
  };

  // TODO Similar to handleEmailEdit pass html here also
  const handleTemplateEdit = async (design, html) => {
    const response = await editTemplateDesign({
      design,
      id: editParam[0].params.id,
      html,
    });

    await editorTemplateById({
      type: "byId",
      value: editParam[0].params.id,
    });
    return response;
  };

  const handleEmailEdit = async (design, html) => {
    const response = await editEmailDesign({
      design,
      id: editParam[0].params.id,
      html,
    });
    await emailById({
      type: "byId",
      value: editParam[0].params.id,
    });
    return response;
  };

  //!SECTION - Merge Tag implementation.

  const handleMergeTagToggle = () => {
    setState((prevState) => ({
      ...prevState,
      mergeTagPopupOpen: !prevState.mergeTagPopupOpen,
    }));
  };

  useEffect(() => {
    const { structureById } = dataSlice;
    if (structureById) {
      const filteredStructure = structureById.filter(
        (structure) =>
          structure.FieldName !== "material_table_id" &&
          structure.Required === "NOT NULL"
      );

      const mergeTags = generateMergeTags(filteredStructure);
      editorOptions.mergeTags = mergeTags;

      if (emailEditorRef.current && emailEditorRef.current.editor) {
        // emailEditorRef.current.editor.setMergeTags([]);
        emailEditorRef.current.editor.setMergeTags(mergeTags);
        handleMergeTagToggle();
      }
    }
  }, [dataSlice.structureById]);

  if (state.loading) {
    return <Loading />;
  }

  // FIXME : TO BE REVIEWED Breaking the Code going to template -------------------------------
  // if (props.edit === false && state.redirect.is === false) {
  //   let navigate = checkToNewProject(
  //     component,
  //     component === "template" ? template : email
  //   );
  //   if (navigate !== undefined) return <Navigate to={navigate} replace />;
  // }
  // --------------------------------------------------------

  if (state.redirect.is) {
    return <Navigate to={props.redirect.to} replace />;
  }

  const returnReference = () => emailEditorRef.current;
  return (
    <div>
      {/* SECTION Editor Nav Component of Exisiting Charlie */}
      {editorLoaded && (
        <EditorNewNav
          component={component}
          edit={props.edit}
          alertRef={props.alertRef}
          params={props.editParam ? props.editParam[0].params : undefined}
          saveEditor={saveEditor}
          editEditor={editEditor}
          handleSave={handleSave}
          autoSaveLoading={state.autoSaveLoading}
          handleReset={handleReset}
          returnReference={returnReference}
          emailEditorRef={emailEditorRef}
        />
      )}
      {/* SECTION Editor Component of Unlayer  */}
      <React.StrictMode>
        <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          onReady={onReady}
          style={{ minHeight: "91vh" }}
          id="editor"
          // projectId={unlayerprojectIds}
          options={editorOptions}
        />
      </React.StrictMode>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // data: state.EditorSlice,
  template: state.TemplateSlice,
  email: state.EmailSlice,
  editorSlice: state.EditorSlice,
  assetSlice: state.AssetSlice,
  imageSlice: state.ImageSlice,
  dataSlice: state.DataSlice,
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  saveTemplate: (templateData) => dispatch(saveTemplate(templateData)),
  saveEmail: (emailData) => dispatch(saveEmail(emailData)),
  reset: () => dispatch(reset()),
  // TEMPLATE
  editTemplateDesign: ({ design, id, html }) =>
    dispatch(editTemplateDesign({ design, id, html })),
  editorTemplateById: (type, value) =>
    dispatch(editorTemplateById(type, value)),
  // EMAIL
  editEmailDesign: ({ design, id, html }) =>
    dispatch(editEmailDesign({ design, id, html })),
  emailById: (type, value) => dispatch(emailById(type, value)),
  // initializeEditor: (param) => dispatch(initializeEditor(param)),
  // IMAGE
  getImages: (type, page, limit, value) =>
    dispatch(getImages(type, page, limit, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
